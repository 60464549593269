export const propertiesObject = {
    'aliasObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.ALIASOBJECT.PROPERTIES.ALIASNAME.LABEL',
                'key': 'AliasName',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.ALIASOBJECT.PROPERTIES.ALIASNAME.DESCRIPTION',
                'validation': {
                    'minLength': 1,
                    'maxLength': 15,
                    'regex': '^[A-Za-z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.ALIASOBJECT.PROPERTIES.ALIASNAME.VALIDATION.REGEX_ERROR_MSG'
                },
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.ALIASOBJECT.PROPERTIES.VERSION.LABEL',
                'key': 'Version',
                'type': 'integer',
                'validation': {
                    'regex': '^(null||undefined|[0-9]*)$',
                    'regexErrMsg': 'OBJECTPROPERTIES.ALIASOBJECT.PROPERTIES.VERSION.VALIDATION.REGEX_ERROR_MSG'
                },
                'placeholder': 'OBJECTPROPERTIES.ALIASOBJECT.PROPERTIES.VERSION.PLACEHOLDER',
                'description': 'OBJECTPROPERTIES.ALIASOBJECT.PROPERTIES.VERSION.DESCRIPTION'
            }
        ]
    },
    'variableObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.VARIABLEOBJECT.PROPERTIES.NAME.LABEL',
                'key': 'Name',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.VARIABLEOBJECT.PROPERTIES.NAME.DESCRIPTION',
                'required': true,
                'validation': {
                    'minLength': 2,
                    'maxLength': 30,
                    'regex': '^[A-Za-z0-9-_]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.VARIABLEOBJECT.PROPERTIES.NAME.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.VARIABLEOBJECT.PROPERTIES.VALUE.LABEL',
                'key': 'Value',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.VARIABLEOBJECT.PROPERTIES.VALUE.DESCRIPTION',
                'required': true,
                'validation': {
                    'minLength': 1,
                    'maxLength': 200
                }
            }
        ]
    },
    'lambdaAuthorizerObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.NAME.LABEL',
                'key': 'Name',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.NAME.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '^[A-Za-z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.NAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30
                },
                'disabled': true
            },
            {
                'label': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.FUNCTIONNAME.LABEL',
                'key': 'FunctionName',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.FUNCTIONNAME.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '^[a-z0-9][-_a-z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.FUNCTIONNAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30,
                    'unique': true
                }
            },
            {
                'label': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.RUNTIME.LABEL',
                'key': 'Runtime',
                'type': 'select<typeLambdaRuntimes>',
                'description': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.RUNTIME.DESCRIPTION',
                'advanced': true,
                'default': 'nodejs16.x',
                'required': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RETENTIONLOGSPERIOD.LABEL',
                'key': 'RetentionLogsPeriod',
                'type': 'select<retentionLogsPeriod>',
                'default': '365',
                'required': true,
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RETENTIONLOGSPERIOD.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.HANDLER.LABEL',
                'key': 'Handler',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.HANDLER.DESCRIPTION',
                'placeholder': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.HANDLER.PLACEHOLDER',
                'validation': {
                    'minLength': 3,
                    'maxLength': 50
                },
                'advanced': true
            },
            {
                'label': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.VARIABLES.LABEL',
                'key': 'Variables',
                'type': 'array<variableObject>',
                'description': 'OBJECTPROPERTIES.LAMBDAAUTHORIZEROBJECT.PROPERTIES.VARIABLES.DESCRIPTION',
                'advanced': true
            }
        ]
    },
    'methodIntegrationObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.METHODINTEGRATIONOBJECT.PROPERTIES.STATUSCODE.LABEL',
                'key': 'StatusCode',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.METHODINTEGRATIONOBJECT.PROPERTIES.STATUSCODE.DESCRIPTION',
                'required': true,
                'validation': {
                    'minLength': 1,
                    'maxLength': 8,
                    'regex': '^[0-9-]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.METHODINTEGRATIONOBJECT.PROPERTIES.STATUSCODE.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.METHODINTEGRATIONOBJECT.PROPERTIES.LAMBDAOUTPUTREGEX.LABEL',
                'key': 'LambdaOutputRegex',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.METHODINTEGRATIONOBJECT.PROPERTIES.LAMBDAOUTPUTREGEX.DESCRIPTION',
                'required': false,
                'validation': {
                    'minLength': 1,
                    'maxLength': 8000
                }
            },
            {
                'label': 'OBJECTPROPERTIES.METHODINTEGRATIONOBJECT.PROPERTIES.MAPPINGRESPONSE.LABEL',
                'key': 'MappingResponse',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.METHODINTEGRATIONOBJECT.PROPERTIES.MAPPINGRESPONSE.DESCRIPTION',
                'required': false,
                'validation': {
                    'minLength': 1,
                    'maxLength': 8000
                }
            }
        ]
    },
    'authorizerObject': {
        'label': 'OBJECTPROPERTIES.AUTHORIZEROBJECT.LABEL',
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.AUTHORIZEROBJECT.PROPERTIES.AUTHID.LABEL',
                'key': 'AuthID',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.AUTHORIZEROBJECT.PROPERTIES.AUTHID.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '^[A-Za-z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.AUTHORIZEROBJECT.PROPERTIES.AUTHID.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 20,
                    'unique': true
                }
            },
            {
                'label': 'OBJECTPROPERTIES.AUTHORIZEROBJECT.PROPERTIES.TYPE.LABEL',
                'key': 'Type',
                'type': 'select<typeAuth>',
                'description': 'OBJECTPROPERTIES.AUTHORIZEROBJECT.PROPERTIES.TYPE.DESCRIPTION',
                'required': true,
                'onChangeActions': [
                    {
                        'action': 'changePropertyType',
                        'target': 'Parameters',
                        'replaceWith': 'actionValue'
                    }
                ]
            },
            {
                'label': 'OBJECTPROPERTIES.AUTHORIZEROBJECT.PROPERTIES.TTL.LABEL',
                'key': 'AuthorizerResultTtlInSeconds',
                'type': 'integer',
                'description': 'OBJECTPROPERTIES.AUTHORIZEROBJECT.PROPERTIES.TTL.DESCRIPTION',
                'required': false,
                'validation': {
                    'min': 0,
                    'max': 3600,
                    'regex': '^(^[0-9][0-9]*)$',
                    'regexErrMsg': 'OBJECTPROPERTIES.AUTHORIZEROBJECT.PROPERTIES.TTL.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.AUTHORIZEROBJECT.PROPERTIES.PARAMETERS.LABEL',
                'key': 'Parameters',
                'type': 'parametersAuthObjectOauth',
                'description': 'OBJECTPROPERTIES.AUTHORIZEROBJECT.PROPERTIES.PARAMETERS.DESCRIPTION',
                'advanced': true
            }
        ]
    },
    'HeaderObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.HEADEROBJECT.PROPERTIES.NAME.LABEL',
                'key': 'Name',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.HEADEROBJECT.PROPERTIES.NAME.DESCRIPTION',
                'required': true,
                'validation': {
                    'minLength': 1,
                    'maxLength': 200,
                }
            },
            {
                'label': 'OBJECTPROPERTIES.HEADEROBJECT.PROPERTIES.VALUE.LABEL',
                'key': 'Value',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.HEADEROBJECT.PROPERTIES.VALUE.DESCRIPTION',
                'required': true,
                'validation': {
                    'minLength': 1,
                    'maxLength': 200
                }
            }
        ]
    },
    'ResponseTemplateObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.RESPONSETEMPLATEOBJECT.PROPERTIES.CONTENTTYPE.LABEL',
                'key': 'ContentType',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.RESPONSETEMPLATEOBJECT.PROPERTIES.CONTENTTYPE.DESCRIPTION',
                'required': true,
            },
            {
                'label': 'OBJECTPROPERTIES.RESPONSETEMPLATEOBJECT.PROPERTIES.TEMPLATE.LABEL',
                'key': 'Template',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.RESPONSETEMPLATEOBJECT.PROPERTIES.TEMPLATE.DESCRIPTION',
                'advanced': true,
                'required': true,
            }
        ]
    },
    'responseObject': {
        'label': 'OBJECTPROPERTIES.RESPONSEOBJECT.LABEL',
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.RESPONSEOBJECT.PROPERTIES.TYPE.LABEL',
                'key': 'ResponseType',
                'type': 'select<typeRes>',
                'description': 'OBJECTPROPERTIES.RESPONSEOBJECT.PROPERTIES.TYPE.DESCRIPTION',
                'required': true,
                'onChangeActions': [
                    {
                        'action': 'changeStatusCode',
                        'target': 'StatusCode',
                        'replaceWith': 'actionValue'
                    }
                ]
            },
            {
                'label': 'OBJECTPROPERTIES.RESPONSEOBJECT.PROPERTIES.ERRORCODE.LABEL',
                'key': 'StatusCode',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.RESPONSEOBJECT.PROPERTIES.ERRORCODE.DESCRIPTION',
                'required': false,
                'validation': {
                    'regex': '^[0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.RESPONSEOBJECT.PROPERTIES.ERRORCODE.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 3,
                    'unique': false
                }
            },
            {
                'label': 'OBJECTPROPERTIES.RESPONSEOBJECT.PROPERTIES.HEADER.LABEL',
                'key': 'ResponseParameters',
                'type': 'array<HeaderObject>',
                'description': 'OBJECTPROPERTIES.RESPONSEOBJECT.PROPERTIES.HEADER.DESCRIPTION',
                'advanced': true,
                'required': false,
            },
            {
                'label': 'OBJECTPROPERTIES.RESPONSEOBJECT.PROPERTIES.RESPONSETEMPLATE.LABEL',
                'key': 'ResponseTemplates',
                'type': 'array<ResponseTemplateObject>',
                'description': 'OBJECTPROPERTIES.RESPONSEOBJECT.PROPERTIES.RESPONSETEMPLATE.DESCRIPTION',
                'required': false,
                'advanced': true,
            }
        ]
    },
    'parametersAuthObjectOauth': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTOAUTH.PROPERTIES.URL.LABEL',
                'key': 'Url',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTOAUTH.PROPERTIES.URL.DESCRIPTION',
                'required': true,
                'validation': {
                    'minLength': 4,
                    'maxLength': 200,
                    'regex': '^((https|http|ftp|rtsp|mms)?://)?(([0-9a-z_!~*\'().&=+$%-]+: )?[0-9a-z_!~*\'().&=+$%-]+@)?(([0-9]{1,3}.){3}[0-9]{1,3}|([0-9a-z_!~*\'()-]+.)*([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].[a-z]{2,6})(:[0-9]{1,4})?((/?)|(/[0-9a-z_!~*\'().;?:@&=+$,%#-]+)+/?)$',
                    'regexErrMsg': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTOAUTH.PROPERTIES.URL.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTOAUTH.PROPERTIES.METHOD.LABEL',
                'key': 'Method',
                'type': 'select<typeHttpMethod>',
                'description': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTOAUTH.PROPERTIES.METHOD.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTOAUTH.PROPERTIES.TOKEN.LABEL',
                'key': 'Token',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTOAUTH.PROPERTIES.TOKEN.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'maxLength': 200
                }
            }
        ]
    },
    'parametersAuthObjectJwt': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTJWT.PROPERTIES.SECRET.LABEL',
                'key': 'Secret',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTJWT.PROPERTIES.SECRET.DESCRIPTION',
                'validation': {
                    'maxLength': 200,
                    'regex': '^[A-Za-z0-9-_]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTJWT.PROPERTIES.SECRET.VALIDATION.REGEX_ERROR_MSG'
                },
                'required': true
            }
        ]
    },
    'parametersAuthObjectBasic': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTBASIC.PROPERTIES.URL.LABEL',
                'key': 'Url',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTBASIC.PROPERTIES.URL.DESCRIPTION',
                'required': true,
                'validation': {
                    'maxLength': 200,
                    'regex': '^((https|http|ftp|rtsp|mms)?://)?(([0-9a-z_!~*\'().&=+$%-]+: )?[0-9a-z_!~*\'().&=+$%-]+@)?(([0-9]{1,3}.){3}[0-9]{1,3}|([0-9a-z_!~*\'()-]+.)*([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].[a-z]{2,6})(:[0-9]{1,4})?((/?)|(/[0-9a-z_!~*\'().;?:@&=+$,%#-]+)+/?)$',
                    'regexErrMsg': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTBASIC.PROPERTIES.URL.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTBASIC.PROPERTIES.METHOD.LABEL',
                'key': 'Method',
                'type': 'select<typeHttpMethod>',
                'description': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTBASIC.PROPERTIES.METHOD.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTBASIC.PROPERTIES.TOKEN.LABEL',
                'key': 'Token',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTBASIC.PROPERTIES.TOKEN.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'maxLength': 200,
                    'regex': '^[A-Za-z0-9-_]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTBASIC.PROPERTIES.TOKEN.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ],
        'fieldToDisplay': 'Url'
    },
    'parametersAuthObjectCustomToken': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTCUSTOMTOKEN.PROPERTIES.FUNCTION.LABEL',
                'key': 'Function',
                'type': 'lambdaAuthorizerObject',
                'description': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTCUSTOMTOKEN.PROPERTIES.FUNCTION.DESCRIPTION',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTCUSTOMTOKEN.PROPERTIES.HEADER.LABEL',
                'key': 'Header',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTCUSTOMTOKEN.PROPERTIES.HEADER.DESCRIPTION',
                'advanced': true,
                'default': 'Authorization',
                'required': true,
                'validation': {
                    'maxLength': 200
                }
            },
            {
                'label': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTCUSTOMTOKEN.PROPERTIES.VALIDATIONREGEXP.LABEL',
                'key': 'ValidationRegexp',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTCUSTOMTOKEN.PROPERTIES.VALIDATIONREGEXP.DESCRIPTION',
                'advanced': true
            }
        ]
    },
    'parametersAuthObjectCustomRequest': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTCUSTOMREQUEST.PROPERTIES.FUNCTION.LABEL',
                'key': 'Function',
                'type': 'lambdaAuthorizerObject',
                'description': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTCUSTOMREQUEST.PROPERTIES.FUNCTION.DESCRIPTION',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTCUSTOMREQUEST.PROPERTIES.IDENTITYSOURCES.LABEL',
                'key': 'IdentitySources',
                'type': 'array<identitySourceObject>',
                'description': 'OBJECTPROPERTIES.PARAMETERSAUTHOBJECTCUSTOMREQUEST.PROPERTIES.IDENTITYSOURCES.DESCRIPTION',
                'advanced': true
            }
        ]
    },
    'identitySourceObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.IDENTITYSOURCEOBJECT.PROPERTIES.TYPE.LABEL',
                'key': 'Type',
                'type': 'select<typeIdentitySource>',
                'description': 'OBJECTPROPERTIES.IDENTITYSOURCEOBJECT.PROPERTIES.TYPE.DESCRIPTION',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.IDENTITYSOURCEOBJECT.PROPERTIES.NAME.LABEL',
                'key': 'Name',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.IDENTITYSOURCEOBJECT.PROPERTIES.NAME.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[a-zA-Z0-9._-]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.IDENTITYSOURCEOBJECT.PROPERTIES.NAME.VALIDATION.REGEX_ERROR_MSG'
                },
                'visibility': {
                    'comparator': 'neq',
                    'field': 'Type',
                    'value': 'STAGE'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.IDENTITYSOURCEOBJECT.PROPERTIES.NAMESTAGE.LABEL',
                'key': 'NameStage',
                'type': 'select<stageVariableProper<variableObject>>',
                'description': 'OBJECTPROPERTIES.IDENTITYSOURCEOBJECT.PROPERTIES.NAMESTAGE.DESCRIPTION',
                'required': true,
                'visibility': {
                    'comparator': 'eq',
                    'field': 'Type',
                    'value': 'STAGE'
                },
                'editable': false
            }
        ]
    },
    'deploymentObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.DEPLOYMENTOBJECT.PROPERTIES.STAGENAME.LABEL',
                'key': 'StageName',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.DEPLOYMENTOBJECT.PROPERTIES.STAGENAME.DESCRIPTION',
                'required': true,
                'validation': {
                    'minLength': 1,
                    'maxLength': 30,
                    'regex': '^[a-zA-Z0-9][-_a-zA-Z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.DEPLOYMENTOBJECT.PROPERTIES.STAGENAME.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.DEPLOYMENTOBJECT.PROPERTIES.DESCRIPTION.LABEL',
                'key': 'Description',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.DEPLOYMENTOBJECT.PROPERTIES.DESCRIPTION.DESCRIPTION',
                'validation': {
                    'minLength': 3,
                    'maxLength': 100
                }
            },
            {
                'label': 'OBJECTPROPERTIES.DEPLOYMENTOBJECT.PROPERTIES.VARIABLES.LABEL',
                'key': 'Variables',
                'type': 'array<variableObject>',
                'description': 'OBJECTPROPERTIES.DEPLOYMENTOBJECT.PROPERTIES.VARIABLES.DESCRIPTION'
            }
        ]
    },
    'resourceObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.RESOURCEOBJECT.PROPERTIES.NAME.LABEL',
                'key': 'Name',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.RESOURCEOBJECT.PROPERTIES.NAME.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '^[{a-zA-Z0-9][-.+_{}a-zA-Z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.RESOURCEOBJECT.PROPERTIES.NAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 1,
                    'maxLength': 50
                }
            },
            {
                'label': 'OBJECTPROPERTIES.RESOURCEOBJECT.PROPERTIES.METHODS.LABEL',
                'key': 'Methods',
                'type': 'array<methodObject>',
                'description': 'OBJECTPROPERTIES.RESOURCEOBJECT.PROPERTIES.METHODS.DESCRIPTION',
                'advanced': true,
                'hiddenInModal': true
            },
            {
                'label': 'OBJECTPROPERTIES.RESOURCEOBJECT.PROPERTIES.SUBRESOURCES.LABEL',
                'key': 'Subresources',
                'type': 'array<resourceObject>',
                'description': 'OBJECTPROPERTIES.RESOURCEOBJECT.PROPERTIES.SUBRESOURCES.DESCRIPTION',
                'advanced': true,
                'hiddenInModal': true
            }
        ]
    },
    'methodObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.METHOD.LABEL',
                'key': 'Method',
                'type': 'select<typeHttpMethod>',
                'description': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.METHOD.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.CORS.LABEL',
                'key': 'CORS',
                'type': 'boolean',
                'description': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.CORS.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.CORSPARAMETERS.LABEL',
                'key': 'CORSParameters',
                'type': 'configurationCORS',
                'description': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.CORSPARAMETERS.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.CORSPARAMETERS.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.AUTHID.LABEL',
                'key': 'AuthID',
                'type': 'select<authProper<Authorizer>>',
                'description': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.AUTHID.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.INTEGRATION.LABEL',
                'key': 'Integration',
                'type': 'select<typeIntegration>',
                'description': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.INTEGRATION.DESCRIPTION',
                'advanced': true,
                'hiddenInModal': true,
                'onChangeActions': [
                    {
                        'action': 'changePropertyType',
                        'target': 'IntegrationParameters',
                        'replaceWith': 'actionValue'
                    }
                ]
            },
            {
                'label': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.INTEGRATIONPARAMETERS.LABEL',
                'key': 'IntegrationParameters',
                'type': 'integracionParametersObjectLambda',
                'description': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.INTEGRATIONPARAMETERS.DESCRIPTION',
                'advanced': true,
                'hiddenInModal': true,
                'visibility': {
                    'comparator': 'notnull',
                    'field': 'Integration'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.INTEGRATIONRESPONSE.LABEL',
                'key': 'IntegrationResponse',
                'type': 'array<methodIntegrationObject>',
                'description': 'OBJECTPROPERTIES.METHODOBJECT.PROPERTIES.INTEGRATIONRESPONSE.DESCRIPTION',
                'advanced': true
            }
        ]
    },
    'integracionParametersObjectLambda': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.FUNCTIONNAME.LABEL',
                'key': 'FunctionName',
                'type': 'select<node<lambda>>',
                'description': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.FUNCTIONNAME.DESCRIPTION',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.ALIAS.LABEL',
                'key': 'Alias',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.ALIAS.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '^[A-Za-z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.ALIAS.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 15
                }
            }
        ]
    },
    'integracionParametersObjectProxyLambda': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDAPROXY.PROPERTIES.FUNCTIONNAME.LABEL',
                'key': 'FunctionName',
                'type': 'select<node<lambda>>',
                'description': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDAPROXY.PROPERTIES.FUNCTIONNAME.DESCRIPTION',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDAPROXY.PROPERTIES.ALIAS.LABEL',
                'key': 'Alias',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDAPROXY.PROPERTIES.ALIAS.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '^[A-Za-z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDAPROXY.PROPERTIES.ALIAS.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 15
                }
            }
        ]
    },
    'integracionParametersObjectProjectVPCLambda': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.FUNCTIONNAME.LABEL',
                'key': 'FunctionName',
                'type': 'select<node<lamvpc>>',
                'description': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.FUNCTIONNAME.DESCRIPTION',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.ALIAS.LABEL',
                'key': 'Alias',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.ALIAS.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '^[A-Za-z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.ALIAS.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 15
                }
            }
        ]
    },
    'integracionParametersObjectCPDVPCLambda': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.FUNCTIONNAME.LABEL',
                'key': 'FunctionName',
                'type': 'select<node<lamvpcm>>',
                'description': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.FUNCTIONNAME.DESCRIPTION',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.ALIAS.LABEL',
                'key': 'Alias',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.ALIAS.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '^[A-Za-z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTLAMBDA.PROPERTIES.ALIAS.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 15
                }
            }
        ]
    },
    'integracionParametersObjectHTTP': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTHTTP.PROPERTIES.URL.LABEL',
                'key': 'Url',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTHTTP.PROPERTIES.URL.DESCRIPTION',
                'required': true,
                'validation': {
                    'minLength': 4,
                    'maxLength': 200,
                    'regex': '\\w+:(\\/?\\/?)[^\\s]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTHTTP.PROPERTIES.URL.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTHTTP.PROPERTIES.METHOD.LABEL',
                'key': 'Method',
                'type': 'select<typeHttpMethod>',
                'description': 'OBJECTPROPERTIES.INTEGRACIONPARAMETERSOBJECTHTTP.PROPERTIES.METHOD.DESCRIPTION',
                'advanced': true
            }
        ]
    },
    'keySchemaObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.KEYSCHEMAOBJECT.PROPERTIES.PARTITIONKEY.LABEL',
                'key': 'PartitionKey',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.KEYSCHEMAOBJECT.PROPERTIES.PARTITIONKEY.DESCRIPTION',
                'required': true,
                'validation': {
                    'notEqual': 'SortKey',
                    'regex': '(^([A-Za-z0-9]))(([-_a-z0-9])*)',
                    'regexErrMsg': 'OBJECTPROPERTIES.KEYSCHEMAOBJECT.PROPERTIES.PARTITIONKEY.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 1,
                    'maxLength': 50
                }
            },
            {
                'label': 'OBJECTPROPERTIES.KEYSCHEMAOBJECT.PROPERTIES.TYPEPARTITIONKEY.LABEL',
                'key': 'TypePartitionKey',
                'type': 'select<typeKey>',
                'description': 'OBJECTPROPERTIES.KEYSCHEMAOBJECT.PROPERTIES.TYPEPARTITIONKEY.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.KEYSCHEMAOBJECT.PROPERTIES.TYPEPARTITIONKEY.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.KEYSCHEMAOBJECT.PROPERTIES.SORTKEY.LABEL',
                'key': 'SortKey',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.KEYSCHEMAOBJECT.PROPERTIES.SORTKEY.DESCRIPTION',
                'validation': {
                    'notEqual': 'PartitionKey',
                    'regex': '^[A-Za-z0-9][-_A-Za-z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.KEYSCHEMAOBJECT.PROPERTIES.SORTKEY.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 1,
                    'maxLength': 50
                }
            },
            {
                'label': 'OBJECTPROPERTIES.KEYSCHEMAOBJECT.PROPERTIES.TYPESORTKEY.LABEL',
                'key': 'TypeSortKey',
                'type': 'select<typeKey>',
                'description': 'OBJECTPROPERTIES.KEYSCHEMAOBJECT.PROPERTIES.TYPESORTKEY.DESCRIPTION',
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.KEYSCHEMAOBJECT.PROPERTIES.TYPESORTKEY.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'indexObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.TYPE.LABEL',
                'key': 'Type',
                'type': 'select<typeIndex>',
                'description': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.TYPE.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.TYPE.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.INDEXNAME.LABEL',
                'key': 'IndexName',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.INDEXNAME.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '^[a-z0-9][-_a-z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.INDEXNAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30
                }
            },
            {
                'label': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.KEYSCHEMA.LABEL',
                'key': 'KeySchema',
                'type': 'keySchemaObject',
                'description': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.KEYSCHEMA.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.KEYSCHEMA.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.PROJECTION.LABEL',
                'key': 'Projection',
                'type': 'projectionObject',
                'description': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.PROJECTION.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.PROJECTION.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.PROVISIONEDUNITS.LABEL',
                'key': 'ProvisionedUnits',
                'type': 'provisionedObject',
                'description': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.PROVISIONEDUNITS.DESCRIPTION',
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.INDEXOBJECT.PROPERTIES.PROVISIONEDUNITS.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'projectionObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.PROJECTIONOBJECT.PROPERTIES.TYPE.LABEL',
                'key': 'Type',
                'type': 'select<typeProjection>',
                'description': 'OBJECTPROPERTIES.PROJECTIONOBJECT.PROPERTIES.TYPE.DESCRIPTION',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.PROJECTIONOBJECT.PROPERTIES.NONKEYATTRIBUTES.LABEL',
                'key': 'NonKeyAttributes',
                'type': 'array<string>',
                'description': 'OBJECTPROPERTIES.PROJECTIONOBJECT.PROPERTIES.NONKEYATTRIBUTES.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'minLength': 3,
                    'maxLength': 30,
                    'regex': '^[a-zA-Z0-9][-._a-zA-Z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.PROJECTIONOBJECT.PROPERTIES.NONKEYATTRIBUTES.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'provisionedObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.PROVISIONEDOBJECT.PROPERTIES.READUNITS.LABEL',
                'key': 'ReadUnits',
                'type': 'integer',
                'description': 'OBJECTPROPERTIES.PROVISIONEDOBJECT.PROPERTIES.READUNITS.DESCRIPTION',
                'required': true,
                'validation': {
                    'min': 1,
                    'max': 100,
                    'regex': '^(^[1-9][0-9]*)$',
                    'regexErrMsg': 'OBJECTPROPERTIES.PROVISIONEDOBJECT.PROPERTIES.READUNITS.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.PROVISIONEDOBJECT.PROPERTIES.WRITEUNITS.LABEL',
                'key': 'WriteUnits',
                'type': 'integer',
                'description': 'OBJECTPROPERTIES.PROVISIONEDOBJECT.PROPERTIES.WRITEUNITS.DESCRIPTION',
                'required': true,
                'validation': {
                    'min': 1,
                    'max': 100,
                    'regex': '^(^[1-9][0-9]*)$',
                    'regexErrMsg': 'OBJECTPROPERTIES.PROVISIONEDOBJECT.PROPERTIES.WRITEUNITS.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'backupObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.BACKUPOBJECT.PROPERTIES.SCHEDULE.LABEL',
                'key': 'Schedule',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.BACKUPOBJECT.PROPERTIES.SCHEDULE.DESCRIPTION',
                'required': true,
                'placeholder': 'OBJECTPROPERTIES.BACKUPOBJECT.PROPERTIES.SCHEDULE.PLACEHOLDER',
                'default': '',
                'validation': {
                    'minLenth': 17,
                    'maxLength': 100,
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.BACKUPOBJECT.PROPERTIES.SCHEDULE.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'streamDBObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.STREAMDBOBJECT.PROPERTIES.LAMBDANAME.LABEL',
                'key': 'LambdaName',
                'type': 'select<node<lambda>>',
                'description': 'OBJECTPROPERTIES.STREAMDBOBJECT.PROPERTIES.LAMBDANAME.DESCRIPTION',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.STREAMDBOBJECT.PROPERTIES.STARTINGPOSITION.LABEL',
                'key': 'StartingPosition',
                'type': 'select<startingPositionType>',
                'description': 'OBJECTPROPERTIES.STREAMDBOBJECT.PROPERTIES.STARTINGPOSITION.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.STREAMDBOBJECT.PROPERTIES.STARTINGPOSITION.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.STREAMDBOBJECT.PROPERTIES.BATCHSIZE.LABEL',
                'key': 'BatchSize',
                'type': 'integer',
                'description': 'OBJECTPROPERTIES.STREAMDBOBJECT.PROPERTIES.BATCHSIZE.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'min': 1,
                    'max': 100,
                    'regexErrMsg': 'OBJECTPROPERTIES.STREAMDBOBJECT.PROPERTIES.BATCHSIZE.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'streamS3Object': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.STREAMS3OBJECT.PROPERTIES.LAMBDANAME.LABEL',
                'key': 'LambdaName',
                'type': 'select<node<lambda>>',
                'description': 'OBJECTPROPERTIES.STREAMS3OBJECT.PROPERTIES.LAMBDANAME.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'minLength': 3,
                    'maxLength': 30,
                    'regexErrMsg': 'OBJECTPROPERTIES.STREAMS3OBJECT.PROPERTIES.LAMBDANAME.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.STREAMS3OBJECT.PROPERTIES.EVENTS.LABEL',
                'key': 'Events',
                'type': 'select<typeEventS3>',
                'description': 'OBJECTPROPERTIES.STREAMS3OBJECT.PROPERTIES.EVENTS.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.STREAMS3OBJECT.PROPERTIES.EVENTS.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.STREAMS3OBJECT.PROPERTIES.FILTER.LABEL',
                'key': 'Filter',
                'type': 'filterObject',
                'description': 'OBJECTPROPERTIES.STREAMS3OBJECT.PROPERTIES.FILTER.DESCRIPTION',
                'required': false,
                'validation': {
                    'regex': '[^\\n]*',
                    'regexErrMsg': 'OBJECTPROPERTIES.STREAMS3OBJECT.PROPERTIES.FILTER.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'streamSQSObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.STREAMSQSOBJECT.PROPERTIES.LAMBDANAME.LABEL',
                'key': 'LambdaName',
                'type': 'select<node<lambda>>',
                'description': 'OBJECTPROPERTIES.STREAMSQSOBJECT.PROPERTIES.LAMBDANAME.DESCRIPTION',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.STREAMSQSOBJECT.PROPERTIES.BATCHSIZE.LABEL',
                'key': 'BatchSize',
                'type': 'integer',
                'description': 'OBJECTPROPERTIES.STREAMSQSOBJECT.PROPERTIES.BATCHSIZE.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'min': 1,
                    'max': 10,
                    'regexErrMsg': 'OBJECTPROPERTIES.STREAMSQSOBJECT.PROPERTIES.BATCHSIZE.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'filterObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.FILTEROBJECT.PROPERTIES.NAME.LABEL',
                'key': 'Name',
                'type': 'select<typeFilter>',
                'description': 'OBJECTPROPERTIES.FILTEROBJECT.PROPERTIES.NAME.DESCRIPTION',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.FILTEROBJECT.PROPERTIES.VALUE.LABEL',
                'key': 'Value',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.FILTEROBJECT.PROPERTIES.VALUE.DESCRIPTION',
                'required': true,
                'validation': {
                    'minLength': 1,
                    'maxLength': 200
                }
            }
        ]
    },
    'certificateObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.CERTIFICATEOBJECT.PROPERTIES.TYPE.LABEL',
                'key': 'Type',
                'type': 'select<typeCertificate>',
                'description': 'OBJECTPROPERTIES.CERTIFICATEOBJECT.PROPERTIES.TYPE.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.CERTIFICATEOBJECT.PROPERTIES.TYPE.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.CERTIFICATEOBJECT.PROPERTIES.FILEPUBLIC.LABEL',
                'key': 'FilePublic',
                'type': 'file',
                'uploadFolder': 'webhost/certificate',
                'description': 'OBJECTPROPERTIES.CERTIFICATEOBJECT.PROPERTIES.FILEPUBLIC.DESCRIPTION',
                'advanced': true,
                'visibility': {
                    'comparator': 'eq',
                    'field': 'Type',
                    'value': 'IMPORT'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.CERTIFICATEOBJECT.PROPERTIES.FILEPRIVATE.LABEL',
                'key': 'FilePrivate',
                'type': 'file',
                'uploadFolder': 'webhost/certificate',
                'description': 'OBJECTPROPERTIES.CERTIFICATEOBJECT.PROPERTIES.FILEPRIVATE.DESCRIPTION',
                'advanced': true,
                'visibility': {
                    'comparator': 'eq',
                    'field': 'Type',
                    'value': 'IMPORT'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.CERTIFICATEOBJECT.PROPERTIES.FILECHAIN.LABEL',
                'key': 'FileChain',
                'type': 'file',
                'uploadFolder': 'webhost/certificate',
                'description': 'OBJECTPROPERTIES.CERTIFICATEOBJECT.PROPERTIES.FILECHAIN.DESCRIPTION',
                'advanced': true,
                'visibility': {
                    'comparator': 'eq',
                    'field': 'Type',
                    'value': 'IMPORT'
                }
            }
        ]
    },
    'alarmDimensions': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.DIMENSIONS.DIMENSIONNAME.LABEL',
                'key': 'Name',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.DIMENSIONS.DIMENSIONNAME.DESCRIPTION',
                'required': false,
                'idRequired': 'mapfreName',
                'validation': {
                    'regex': '^[a-zA-z0-9][-_a-zA-z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.NAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 255
                }
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.DIMENSIONS.DIMENSIONVALUE.LABEL',
                'key': 'Value',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.DIMENSIONS.DIMENSIONVALUE.DESCRIPTION',
                'required': true,
                'idRequired': 'mapfreName',
                'validation': {
                    'regex': '^[a-zA-z0-9:_-][-_a-zA-z0-9:_-]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.NAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 255
                }
            },
        ]
    },
    'configurationAlarmsObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.CONFIGURATIONALARMSOBJECT.PROPERTIES.EMAILTO.LABEL',
                'key': 'emailTo',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.CONFIGURATIONALARMSOBJECT.PROPERTIES.EMAILTO.DESCRIPTION',
                'advanced': false,
                'validation': {
                    'minLength': 3,
                    'maxLength': 30,
                    'regex': '^[^@]+@[^@]+.[a-zA-Z]{2,}$',
                    'regexErrMsg': 'OBJECTPROPERTIES.CONFIGURATIONALARMSOBJECT.PROPERTIES.EMAILTO.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'configurationCORS': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.CONFIGURATIONCORS.PROPERTIES.METHODS.LABEL',
                'key': 'Methods',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.CONFIGURATIONCORS.PROPERTIES.METHODS.DESCRIPTION',
                'advanced': true,
                'placeholder': 'OBJECTPROPERTIES.CONFIGURATIONCORS.PROPERTIES.METHODS.PLACEHOLDER',
                'validation': {
                    'maxLength': 200
                }
            },
            {
                'label': 'OBJECTPROPERTIES.CONFIGURATIONCORS.PROPERTIES.HEADERS.LABEL',
                'key': 'Headers',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.CONFIGURATIONCORS.PROPERTIES.HEADERS.DESCRIPTION',
                'advanced': true,
                'placeholder': 'OBJECTPROPERTIES.CONFIGURATIONCORS.PROPERTIES.HEADERS.PLACEHOLDER',
                'validation': {
                    'maxLength': 200
                }
            },
            {
                'label': 'OBJECTPROPERTIES.CONFIGURATIONCORS.PROPERTIES.ORIGIN.LABEL',
                'key': 'Origin',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.CONFIGURATIONCORS.PROPERTIES.ORIGIN.DESCRIPTION',
                'advanced': true,
                'placeholder': 'OBJECTPROPERTIES.CONFIGURATIONCORS.PROPERTIES.ORIGIN.PLACEHOLDER',
                'validation': {
                    'maxLength': 200
                }
            }
        ]
    },
    'graphQLAPIObject': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.GRAPHQLAPIOBJECT.PROPERTIES.NAME.LABEL',
                'key': 'Name',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.GRAPHQLAPIOBJECT.PROPERTIES.NAME.DESCRIPTION',
                'placeholder': 'OBJECTPROPERTIES.GRAPHQLAPIOBJECT.PROPERTIES.NAME.PLACEHOLDER',
                'validation': {
                    'maxLength': 30
                },
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.GRAPHQLAPIOBJECT.PROPERTIES.AUTHENTICATIONTYPE.LABEL',
                'key': 'AuthenticationType',
                'type': 'select<graphQLAPIAuthType>',
                'description': 'OBJECTPROPERTIES.GRAPHQLAPIOBJECT.PROPERTIES.AUTHENTICATIONTYPE.DESCRIPTION',
                'placeholder': 'OBJECTPROPERTIES.GRAPHQLAPIOBJECT.PROPERTIES.AUTHENTICATIONTYPE.PLACEHOLDER',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.GRAPHQLAPIOBJECT.PROPERTIES.LOGCONFIG.LABEL',
                'key': 'LogConfig',
                'type': 'graphQLAPILogConfig',
                'description': 'OBJECTPROPERTIES.GRAPHQLAPIOBJECT.PROPERTIES.LOGCONFIG.DESCRIPTION'
            },
            {
                'label': 'OBJECTPROPERTIES.GRAPHQLAPIOBJECT.PROPERTIES.OPENIDCONNECTCONFIG.LABEL',
                'key': 'OpenIDConnectConfig',
                'type': 'AppSyncOpenIDConnectConfig',
                'description': 'OBJECTPROPERTIES.GRAPHQLAPIOBJECT.PROPERTIES.OPENIDCONNECTCONFIG.DESCRIPTION'
            }
        ]
    },
    'graphQLAPILogConfig': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.GRAPHQLAPILOGCONFIG.PROPERTIES.CLOUDWATCHLOGSROLEARN.LABEL',
                'key': 'CloudWatchLogsRoleArn',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.GRAPHQLAPILOGCONFIG.PROPERTIES.CLOUDWATCHLOGSROLEARN.DESCRIPTION',
                'validation': {
                    'maxLength': 30
                }
            },
            {
                'label': 'OBJECTPROPERTIES.GRAPHQLAPILOGCONFIG.PROPERTIES.EXCLUDEVERBOSECONTENT.LABEL',
                'key': 'ExcludeVerboseContent',
                'type': 'boolean',
                'description': 'OBJECTPROPERTIES.GRAPHQLAPILOGCONFIG.PROPERTIES.EXCLUDEVERBOSECONTENT.DESCRIPTION'
            },
            {
                'label': 'OBJECTPROPERTIES.GRAPHQLAPILOGCONFIG.PROPERTIES.FIELDLOGLEVEL.LABEL',
                'key': 'FieldLogLevel',
                'type': 'select<AppSyncLogLevel>',
                'description': 'OBJECTPROPERTIES.GRAPHQLAPILOGCONFIG.PROPERTIES.FIELDLOGLEVEL.DESCRIPTION'
            }
        ]
    },
    'AppSyncOpenIDConnectConfig': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.APPSYNCOPENIDCONNECTCONFIG.PROPERTIES.AUTHTTL.LABEL',
                'key': 'AuthTTL',
                'type': 'integer',
                'description': 'OBJECTPROPERTIES.APPSYNCOPENIDCONNECTCONFIG.PROPERTIES.AUTHTTL.DESCRIPTION',
                'validation': {
                    'regex': '^(^[1-9][0-9]*)$',
                    'regexErrMsg': 'OBJECTPROPERTIES.APPSYNCOPENIDCONNECTCONFIG.PROPERTIES.AUTHTTL.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.APPSYNCOPENIDCONNECTCONFIG.PROPERTIES.CLIENTID.LABEL',
                'key': 'ClientId',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.APPSYNCOPENIDCONNECTCONFIG.PROPERTIES.CLIENTID.DESCRIPTION'
            },
            {
                'label': 'OBJECTPROPERTIES.APPSYNCOPENIDCONNECTCONFIG.PROPERTIES.FIELDLOGLEVEL.LABEL',
                'key': 'FieldLogLevel',
                'type': 'select<AppSyncLogLevel>',
                'description': 'OBJECTPROPERTIES.APPSYNCOPENIDCONNECTCONFIG.PROPERTIES.FIELDLOGLEVEL.DESCRIPTION'
            }
        ]
    },
    'WSAuthorizerObject': {
        'label': 'OBJECTPROPERTIES.WSAUTHORIZEROBJECT.PROPERTIES',
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.WSAUTHORIZEROBJECT.PROPERTIES.AUTHID.LABEL',
                'key': 'AuthID',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.WSAUTHORIZEROBJECT.PROPERTIES.AUTHID.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '^[A-Za-z0-9]*$',
                    'regexErrMsg': 'OBJECTPROPERTIES.WSAUTHORIZEROBJECT.PROPERTIES.AUTHID.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30
                }
            },
            {
                'label': 'OBJECTPROPERTIES.WSAUTHORIZEROBJECT.PROPERTIES.PARAMETERS.LABEL',
                'key': 'Parameters',
                'type': 'parametersAuthObjectCustomRequest',
                'description': 'OBJECTPROPERTIES.WSAUTHORIZEROBJECT.PROPERTIES.PARAMETERS.DESCRIPTION',
                'required': true,
                'advanced': true
            }
        ]
    },
    'apiGatewayRoute': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.APIGATEWAYROUTE.PROPERTIES.NAME.LABEL',
                'key': 'Name',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.APIGATEWAYROUTE.PROPERTIES.NAME.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '(^[{a-zA-Z0-9][-._{}a-zA-Z0-9]*$)|(^\\$connect$)|(^\\$disconnect$)|(^\\$default$)',
                    'regexErrMsg': 'OBJECTPROPERTIES.APIGATEWAYROUTE.PROPERTIES.NAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 50,
                    'unique': true
                }
            },
            {
                'label': 'OBJECTPROPERTIES.APIGATEWAYROUTE.PROPERTIES.AUTHID.LABEL',
                'key': 'AuthID',
                'type': 'select<authProper<WSAuthorizerObject>>',
                'description': 'OBJECTPROPERTIES.APIGATEWAYROUTE.PROPERTIES.AUTHID.DESCRIPTION',
                'advanced': true,
                'visibility': {
                    'comparator': 'eq',
                    'field': 'Name',
                    'value': '$connect'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.APIGATEWAYROUTE.PROPERTIES.INTEGRATION.LABEL',
                'key': 'Integration',
                'type': 'select<typeWSIntegration>',
                'description': 'OBJECTPROPERTIES.APIGATEWAYROUTE.PROPERTIES.INTEGRATION.DESCRIPTION',
                'advanced': true,
                'hiddenInModal': true,
                'required': true,
                'onChangeActions': [
                    {
                        'action': 'changePropertyType',
                        'target': 'IntegrationParameters',
                        'replaceWith': 'actionValue'
                    }
                ]
            },
            {
                'label': 'OBJECTPROPERTIES.APIGATEWAYROUTE.PROPERTIES.INTEGRATIONPARAMETERS.LABEL',
                'key': 'IntegrationParameters',
                'type': 'integracionParametersObjectLambda',
                'description': 'OBJECTPROPERTIES.APIGATEWAYROUTE.PROPERTIES.INTEGRATIONPARAMETERS.DESCRIPTION',
                'advanced': true,
                'hiddenInModal': true,
                'visibility': {
                    'comparator': 'notnull',
                    'field': 'Integration'
                }
            }
        ]
    },
    'lambdaConfigVPC': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.VPCCONFIG.PROPERTIES.VPCTYPE.LABEL',
                'key': 'VPCType',
                'type': 'select<VPCType>',
                'description': 'OBJECTPROPERTIES.VPCCONFIG.PROPERTIES.VPCTYPE.DESCRIPTION',
                'required': true,
                'onChangeActions': [
                    {
                        'action': 'changeNodeLogo',
                        'replaceWith': 'logoValue'
                    }
                ],
                'validation': {
                    'regex': '^(INTERNET|PRIVATE)$',
                    'regexErrMsg': 'OBJECTPROPERTIES.VPCCONFIG.PROPERTIES.VPCTYPE.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'mapfreLambdaConfigVPC': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.VPCCONFIG.PROPERTIES.VPCTYPE.LABEL',
                'key': 'VPCType',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.VPCCONFIG.PROPERTIES.VPCTYPE.DESCRIPTION',
                'default': 'CPD',
            }
        ]
    },
    'apiConfigVPC': {
        'properties': [
            {
                'label': 'OBJECTPROPERTIES.APICONFIGVPC.PROPERTIES.VPC.LABEL',
                'key': 'VPC',
                'type': 'array<VPCID>',
                'description': 'OBJECTPROPERTIES.APICONFIGVPC.PROPERTIES.VPC.DESCRIPTION',
                'advanced': true
            }
        ]
    },
    'VPCID':
    {
        'properties':
            [
                {
                    'label': 'OBJECTPROPERTIES.VPCID.PROPERTIES.VPCID.LABEL',
                    'key': 'VPCID',
                    'type': 'string',
                    'description': 'OBJECTPROPERTIES.VPCID.PROPERTIES.VPCID.DESCRIPTION',
                    'required': true,
                    'validation': {
                        'regex': '^vpc-([a-z0-9])*$',
                        'regexErrMsg': 'OBJECTPROPERTIES.VPCID.PROPERTIES.VPCID.VALIDATION.REGEX_ERROR_MSG'
                    }
                }
            ]
    }
}
